@keyframes zoomIn {
    0%   {transform: scale(0.5); opacity: 0}
    100% {transform: scale(1); opacity: 1;}
}

@keyframes spin {
    0% {transform: rotate(0)}
    100% {transform: rotate(360deg)}
}

.spin {
    animation: spin 2s infinite linear;
}